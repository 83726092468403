import ApiClient from "../../Api/apiClient";

export const getOrgDetailsByOrgAliasApi = async (alias: string) => {
  const response = await ApiClient.getInstance().get(
    `/organization/getOrgnizationByAlias/${alias}`
  );
  return response;
};
export const loginAdminApi = async (adminDetails:{username:string,password:string}) => {
    const response = await ApiClient.getInstance().post(
      `/admin/login`,adminDetails
    );
    return response;
  };
  
  export const getValidityOfOrgWithDept = async (
    deptId: string,
    orgId: string
  ) => {
      const response =await  ApiClient.getInstance().get(
        `/department/validateDept/${orgId}/${deptId}`
      );
      return response;
  };
