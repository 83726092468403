import { useEffect, useRef } from "react";
import { Edit, Logout } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import { saveToLocalStorage } from "../Utils/Utils";
import { Link } from "react-router-dom";

const UserProfile = ({ onClose }: any) => {
  const { admin } = useStateContext();
  const profileRef = useRef<any>(null);

  useEffect(() => {
    // Function to handle clicks outside the profile component
    const handleClickOutside = (event:any) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        onClose(); // Call the close function if clicked outside
      }
    };

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={profileRef} // Attach the ref to the UserProfile container
      className="absolute bottom-[-190px] right-[1%] bg-white shadow-orange-200 px-5 py-5 shadowForDIv rounded-md flex-col gap-3 z-50"
    >
      <p className="text-sm text-gray-500">{admin.name}</p>
      <p className="mb-6">{admin.email}</p>

      <Link to={"/dashboard/change-password"}>
        <div
          className="flex items-center gap-1 px-5 py-1 rounded-md bg-neutral-100 cursor-pointer"
          onClick={onClose}
        >
          <Edit />
          <p>Change Password</p>
        </div>
      </Link>

      <div className="border-b-gray-200 border-1 my-5 w"></div>

      <button
        className="bg-red-600 px-5 py-1 text-white rounded-md w-full cursor-pointer"
        onClick={() => {
          saveToLocalStorage("userDetails", {});
          window.location.href = "/";
        }}
      >
        <Logout />
        Logout
      </button>
    </div>
  );
};

export default UserProfile;
