import React, { Component } from "react";
import logo from "./../../Assets/multi-logo.png";
import style from "./login.module.css";
import ButtonWithBackground from "../../Neet-Components/Buttons/ButtonWithBackground";
import TextFieldSimple from "../../Neet-Components/Text-Fields/TextFieldSimple";
import TextFieldPassword from "../../Neet-Components/Text-Fields/TextFieldPassword";
import {    
  validateEmail,
  validateObject,
} from "../../Globals/Functions";
import Loading_Page from "../../Neet-Components/Loading-Page/Loading_Page";
import { loginAdminApi } from "./apiHandler";
import { StateContext } from "../../context/ContextProvider";
import { cookiesSaved, errorTypeLogin, loadingEnu } from "../../constants";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage, saveToLocalStorage } from "../../Utils/Utils";

interface LoginPageProps {
  navigate:any;
}
interface LoginPageState {  
  error: string;
  loading: string;
  admin:any;
}
export class Login extends Component<LoginPageProps, LoginPageState> {
  constructor(props: LoginPageProps) {
    super(props);
    this.state = {
      admin: {
        username: "",        
        password: "",
        
      },      
      error: errorTypeLogin.NONE,
      loading: loadingEnu.NONE,      
    };
  }
  static contextType:any = StateContext; // Assign StateContext to static contextType property  
  componentDidMount(): void {                    
    // this.getUserDetailsAndLogin();    
  }
  getUserDetailsAndLogin=async()=>{
    const {setCookie,setAdmin,setIsUserLogedIn}:any=this.context;
    setIsUserLogedIn(false);          
    this.setState({ loading: loadingEnu.PAGELOADING });            
    const userDetails=await getFromLocalStorage("userDetails");    
    console.log(userDetails)
    if(userDetails?.email){
      setAdmin(userDetails);        
        setIsUserLogedIn(true);          
        this.setState({ loading: loadingEnu.NONE });            
        console.log("you are alreday logedin")        
        setTimeout(() => {
          this.props.navigate("/dashboard/apartments")
        }, 300);
        return
    }
    else{
      this.setState({loading:loadingEnu.NONE})
    }  
    this.setState({loading:loadingEnu.NONE})
  }  
  onLoginButtonClicked = async () => {    
    const {setCookie,setAdmin,setIsUserLogedIn}:any=this.context;
    this.setState({ loading: loadingEnu.BUTTONLOADING });
    const validateEmailAddress = validateEmail(
      this.state.admin.username.toLocaleLowerCase()
    );
    if (!validateEmailAddress) {
      this.setState({ error: errorTypeLogin.Username });
      this.setState({ loading: loadingEnu.NONE });
      return;
    }
    const isAllValueAreValid = validateObject(this.state.admin,[""]);            
    if (isAllValueAreValid === "right") {
      const res: any = await loginAdminApi({username:this.state.admin?.username.toLowerCase(),password:this.state.admin?.password});      
      console.log("lgoin response",res)
      if (res.isSuccess && res.statusCode==200) {
        setCookie(cookiesSaved.login, this.state.admin, {
          path: "/",
        });
        setCookie(cookiesSaved.adminId, res?.data?.data?._id, {
          path: "/",
        });        
        setCookie(cookiesSaved.auth_Token, res?.data?.token, {
          path: "/",
        });
        console.log("res?.data?.data",res?.data?.data)
        setAdmin(res?.data?.data);
        saveToLocalStorage("userDetails",res.data.data);        
        setIsUserLogedIn(true);  
        this.props.navigate("/dashboard/apartments")            
        this.setState({ loading: loadingEnu.NONE });                    
      } else {
        this.setState({ error: errorTypeLogin.ALL });
        this.setState({ loading: loadingEnu.NONE });
      }
    } else {
      this.setState({ error: isAllValueAreValid });
      this.setState({ loading: loadingEnu.NONE });
    }
  };
  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed');
      this.onLoginButtonClicked();
    }
  };
  render() {    
    const { error, loading,admin } = this.state;
    if (loading === loadingEnu.PAGELOADING) {
      return <Loading_Page />;
    } else {
      return (
        <div className={style.login}>
          <div className={style.container}>
          <div className={style.logo_title}>
            <img
              src={
                logo
              }
              className={style.logo}            
            />            
            <p className={style.title}>
              Enter your credentials to access your account
            </p>
          </div>
          <div className={""}>
            <TextFieldSimple
              placeholder={"Username"}
              defaultValue={this.state.admin?.username}
              errorMsg={
                error === errorTypeLogin.Username || error === errorTypeLogin.ALL
                  ? "Invalid username"
                  : ""
              }
              handleKeyPress={this.handleKeyDown}
              onChange={(e: any) => {
                this.setState({
                  admin: {
                    ...admin,
                    ["username"]: e.target.value,
                  },
                });
              }}
            />
            <p className="ml-2 font-semibold text-neutral-600 mb-1">Password</p>
            <TextFieldPassword
              placeholder={"Password"}
              defaultValue={this.state.admin?.password}
              errorMsg={
                error === errorTypeLogin.Password || error === errorTypeLogin.ALL
                  ? "Incorrect Password"
                  : ""
              }
              onChange={(e: any) => {
                this.setState({
                  admin: {
                    ...admin,
                    ["password"]: e.target.value,
                  },
                });
              }}
              handleKeyPress={this.handleKeyDown}
            />
            <ButtonWithBackground
              buttonName="Login"
              onClick={this.onLoginButtonClicked}
              buttonLoading={loading === loadingEnu.BUTTONLOADING}
            />
            <p
              className={style.forgot_link}
              onClick={() => {          
                this.props.navigate("/forgot-password")    
                
              }}
            >
              Forgot Password?
            </p>
          </div>                    
          </div>
        </div>
      );
    }
  }
}



const LoginWraper = () => {
  const navigate = useNavigate();
  return (
<Login navigate={navigate}/>
  )
}

export default LoginWraper
