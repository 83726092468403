import {
  Button,
  CircularProgress,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { Component } from "react";
import "./changePassword.css";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { updatePasswordApi } from "../../pages/ForgotPassword/apiHandler";
import TextFieldWithBothIcon from "../TextFieldWithBothIcon";
import { StateContext } from "../../context/ContextProvider";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface ChangePasswordProps {
  adminToken: string;
}
interface ChangePasswordState {
  loading: boolean;
  error: boolean;
  newPassword: string;
  oldPassword: string;
  reTypeNewPassword: string;
  showOldPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  errorType: string;
}

export default class ChangePassword extends Component<
  ChangePasswordProps,
  ChangePasswordState
> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: ChangePasswordProps) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      newPassword: "",
      oldPassword: "",
      reTypeNewPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      errorType: "none",
    };
  }

  validatePasswordInputs = () => {
    const { oldPassword, newPassword, reTypeNewPassword } = this.state;

    if (oldPassword.trim().length === 0) {
      this.setState({ error: true, errorType: "oldPasswordEmpty" });
      return false;
    }
    if (newPassword.trim().length === 0) {
      this.setState({ error: true, errorType: "newPasswordEmpty" });
      return false;
    }
    if (reTypeNewPassword.trim().length === 0) {
      this.setState({ error: true, errorType: "reTypePasswordEmpty" });
      return false;
    }
    if (newPassword !== reTypeNewPassword) {
      this.setState({ error: true, errorType: "passwordMismatch" });
      return false;
    }
    return true;
  };

  onUpdatePasswordButtonPressed = async () => {
    const { admin } = this.context as any;
    this.setState({ loading: true, error: false });

    if (!this.validatePasswordInputs()) {
      this.setState({ loading: false });
      return;
    }

    const response: any = await updatePasswordApi(
      admin.email,
      this.state.newPassword,
      this.state.oldPassword
    );

    if (response.isSuccess) {
      this.setState({ loading: false });
      swal("Success", "Password updated successfully!", "success");
    } else if (response.statusCode === 401) {
      this.setState({ loading: false, error: true, errorType: "authError" });
      swal("Error", "Unauthorized request", "error");
    } else if (response.message) {
      this.setState({ loading: false, error: true });
      swal({
        title: response.message,
        icon: "error",
        dangerMode: true,
      });
    } else {
      this.setState({ loading: false });
      swal({
        title: "Old password is not correct.!",
        icon: "error",
        dangerMode: true,
      });
    }
  };

  handlePasswordVisibilityToggle = (field: "old" | "new" | "confirm") => {
    if (field === "old") {
      this.setState({ showOldPassword: !this.state.showOldPassword });
    } else if (field === "new") {
      this.setState({ showNewPassword: !this.state.showNewPassword });
    } else {
      this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }
  };

  render() {
    const {
      showConfirmPassword,
      showNewPassword,
      showOldPassword,
      error,
      errorType,
      loading,
    } = this.state;
    const { currentColor } = this.context as any;

    return (
      <div className="change-password-container flex items-center justify-center">
        <div className="change-password-card gap-5 flex flex-col w-[50%]">
          <h2 className="change-password-title text-[25px] text-neutral-600 font-bold">Change Password</h2>

          <TextField
            fullWidth
            type={showOldPassword ? "text" : "password"}
            label="Old Password"
            error={error && errorType === "oldPasswordEmpty"}
            helperText={
              error && errorType === "oldPasswordEmpty"
                ? "Please enter your old password."
                : ""
            }
            onChange={(e) =>
              this.setState({ oldPassword: e.target.value, error: false })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.handlePasswordVisibilityToggle("old")}
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            type={showNewPassword ? "text" : "password"}
            label="New Password"
            error={error && errorType === "newPasswordEmpty"}
            helperText={
              error && errorType === "newPasswordEmpty"
                ? "Please enter a new password."
                : ""
            }
            onChange={(e) =>
              this.setState({ newPassword: e.target.value, error: false })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.handlePasswordVisibilityToggle("new")}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm New Password"
            error={error && errorType === "reTypePasswordEmpty"}
            helperText={
              error && errorType === "reTypePasswordEmpty"
                ? "Please confirm your new password."
                : errorType === "passwordMismatch"
                ? "Passwords do not match."
                : ""
            }
            onChange={(e) =>
              this.setState({ reTypeNewPassword: e.target.value, error: false })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      this.handlePasswordVisibilityToggle("confirm")
                    }
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="change-password-buttons">            
            <Button
              variant="contained"
              className="change-password-button-update"
              onClick={this.onUpdatePasswordButtonPressed}
              style={{
                textTransform: "none",
                fontSize: "17px",
                height: "40px",
                background: currentColor,
              }}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    height: "23px!important",
                    width: "23px!important",
                    color: "#fff",
                  }}
                />
              ) : (
                "Update Password"
              )}
            </Button>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
