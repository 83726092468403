import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

interface Apartment {
  apartment: any;
  role: string;
}

interface SelectApartmentProps {
  onStatusChange: (apartment: string | null, role: string | null) => void;
  apartments: Apartment[];
  value?: any;
}

// Customizing scrollbar style for the dropdown
const CustomMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300, // Max height of dropdown
    },
  },
  sx: {
    "& .MuiPaper-root": {
      "&::-webkit-scrollbar": {
        width: "20px", // Scrollbar width
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "gray", // Scrollbar color
        // borderRadius: "10px",
      },
    },
  },
};

export default function SelectApartmentOneOfAll({
  onStatusChange,
  apartments,
  value,
}: SelectApartmentProps) {
  const [status, setStatus] = React.useState<string>(
    apartments.length > 0 ? value?._id ?? apartments[0].apartment._id : "no"
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selectedApartment = apartments.find(
      (apartment) => apartment.apartment._id === selectedValue
    );
    if (selectedApartment) {
      setStatus(selectedValue);
      onStatusChange(selectedApartment.apartment, selectedApartment.role);
    }
  };

  return (
    <Box
      sx={{
        width: 400,
        background: "#fff",
        height: "50px",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        paddingTop: "17px",
      }}
    >
      <FormControl fullWidth size="small" color="primary">
        <InputLabel id="demo-simple-select-label" style={{ fontSize: 18 }}>
          Select Unit
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Select Unit"
          onChange={handleChange}
          color="primary"
          MenuProps={CustomMenuProps} // Apply custom scrollbar styling
        >
          {apartments.length <= 0 && (
            <MenuItem value="no">No Apartment Found</MenuItem>
          )}
          {apartments.length > 0 &&
            apartments.map((apartment) => (
              <MenuItem
                key={apartment.apartment.name}
                value={apartment.apartment._id}
              >
                {apartment.apartment.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}




// import * as React from "react";

// interface Apartment {
//   apartment: any;
//   role: string;
// }

// interface SelectApartmentProps {
//   onStatusChange: (apartment: string | null, role: string | null) => void;
//   apartments: Apartment[];
//   value?: any;
// }

// export default function SelectApartmentOneOfAll({
//   onStatusChange,
//   apartments,
//   value
// }: SelectApartmentProps) {
//   const [status, setStatus] = React.useState<string>(
//     apartments.length > 0 ? value?._id ?? apartments[0].apartment._id : "no"
//   );

//   const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedValue = event.target.value;
//     const selectedApartment = apartments.find(
//       (apartment) => apartment.apartment._id === selectedValue
//     );
//     if (selectedApartment) {
//       setStatus(selectedValue);
//       onStatusChange(selectedApartment.apartment, selectedApartment.role);
//     }
//   };

//   return (
//     <div style={{ width: "400px", backgroundColor: "#fff", padding: "0px 10px", borderRadius: "4px" ,border:"1px solid black"}}>
//       <label style={{ display: "block", marginBottom: "2px", fontSize: "12px" }}>Select Unit</label>
//       <select
//         value={status}
//         onChange={handleChange}
//         style={{ width: "100%", height: "25px", fontSize: "16px", borderRadius: "4px", padding: "5px" }}
//       >
//         {apartments.length <= 0 && (
//           <option value="no">No Apartment Found</option>
//         )}
//         {apartments.length > 0 &&
//           apartments.map((apartment) => (
//             <option key={apartment.apartment._id} value={apartment.apartment._id}>
//               {apartment.apartment.name}
//             </option>
//           ))}
//       </select>
//     </div>
//   );
// }
