import { Component } from "react";
import { AparmetsComponentWrap } from "./AparmetsComponent";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import {
  Add,
  Delete,
  Edit,
  LockClock,
  LockClockSharp,
  QrCode,
  Settings,
} from "@mui/icons-material";
import { DataGrid, gridClasses, GridColDef } from "@mui/x-data-grid";
import AddApartments from "../../../components/AddApartments";
import SimpleMap from "../../../maps/Maps2";
import { AdminRoles, LevelsForLock } from "../../../constants";
import { GrUserAdmin } from "react-icons/gr";
import { BsBuildingGear } from "react-icons/bs";
import { getBuildingDetailsWithTypeName } from "../../../data/BuildingTypes";
import CustomAlert from "../../../components/CustomAlert";
import style from "./settings.module.css";
import { imageBaseUrl } from "../../../Utils/Constants";
import LinearIndeterminate from "../../../Neet-Components/Loading-Page/LinearLoader";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import { IoMdLock } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import ShareIcon from "@mui/icons-material/Share";
import GenerateUserLink from "../../../components/GenerateUserLink";
import styled from "@emotion/styled";
import ChangePassword from "../../../components/changePassword/ChangePassword";

interface Props {
  controller: AparmetsComponentWrap;
}
export enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& ::-webkit-scrollbar": {
    width: "16px",
  },
  "& ::-webkit-scrollbar-track": {
    backgroundColor: "#f5f5f5",
  },
  "& ::-webkit-scrollbar-thumb": {
    borderRadius: "2px",
    backgroundColor: "gray",
  },
  // [`& .${gridClasses.row}`]: {
  //   background:  currentMode === "Dark" ? "#222a32" : "#ffffff",
  //   "&:hover": {
  //     background: currentMode === "Dark" ? "#646f7a" : "#f0f0f0",
  //   },
  // },
  // [`& .${gridClasses.virtualScroller}`]: {
  //   background:  currentMode === "Dark" ? "#222a32" : "#ffffff",    
  // },
  // [`& .${gridClasses.footerContainer}`]: {
  //   background:  currentMode === "Dark" ? "#222a32" : "#ffffff",    
  // }, 
}));
export class AparmetsView extends Component<Props> {
  render() {
    const { controller } = this.props;
    const { admin, currentBuildingType, currentColor }: any =
      this.props.controller.context;
    const columns: GridColDef[] =
      admin.lockCredentials.length > 0
        ? [
            {
              field: "logo",
              headerName: "Logo",
              width: 120,
              headerAlign: "center",
              renderCell: (index) => {
                return index.api.getRow(index.row.id)?.apartment?.thumbLogo ? (
                  <div className={style.logo_container}>
                    <img
                      src={
                        imageBaseUrl +
                        index.api.getRow(index.row.id).apartment.thumbLogo
                      }
                    />
                  </div>
                ) : (
                  <div className={style.logo_container}>
                    <p>--</p>
                  </div>
                );
              },
            },
            { field: "name", headerName: "Name", width: 180 },
            { field: "address", headerName: "Address", width: 200 },
            // { field: "phoneNumber", headerName: "Phone Number", width: 150 },
            {
              field: "edit",
              headerName: "Edit",
              width: 50,
              renderCell: (index) => {
                return index.api.getRow(index.row.id).role ==
                  AdminRoles.SuperAdmin ||
                  index.api.getRow(index.row.id).role == AdminRoles.Manager ||
                  admin.role == AdminRoles.SuperAdmin ? (
                  <div
                    className="flex-row justify-center gap-5 cursor-pointer"
                    onClick={() => {
                      // console.log(index.api.getRow(index.row.id));
                      this.props.controller.onShowEditApartmentWithValue(
                        index.api.getRow(index.row.id)
                      );
                    }}
                  >
                    <Edit />
                  </div>
                ) : (
                  <Edit color={"disabled"} />
                );
              },
            },
            // {
            //   field: "Remove Logo",
            //   headerName: "Remove Logo",
            //   width: 100,
            //   renderCell: (index) => {
            //     return index.api.getRow(index.row.id).role != AdminRoles.Viewer &&
            //       index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
            //       <div className="flex-row justify-center gap-5 cursor-pointer">
            //         <CustomAlert
            //           loading={controller.state.loading == loadingEnu.REMOVELOGO}
            //           msg={"Do you want to remove logo?"}
            //           onPressDelete={() => {
            //             controller.removeLogo(
            //               index.api.getRow(index.row.id).apartment._id
            //             );
            //           }}
            //         />
            //       </div>
            //     ) : (
            //       <Delete color={"disabled"} />
            //     );
            //   },
            // },
            // {
            //   field: "delete",
            //   headerName: "Delete",
            //   width: 100,
            //   headerAlign: "center",
            //   align: "center",
            //   renderCell: (index) => {
            //     return index.api.getRow(index.row.id).role ==
            //       AdminRoles.Owner ||
            //       index.api.getRow(index.row.id).role ==
            //         AdminRoles.SuperAdmin ||
            //       admin.role == AdminRoles.SuperAdmin ? (
            //       <div className="flex-row justify-center gap-5 cursor-pointer">
            //         <CustomAlert
            //           onPressDelete={() => {
            //             console.log(index.api.getRow(index.row.id));
            //             controller.deleteApartment(
            //               index.api.getRow(index.row.id).apartment._id
            //             );
            //           }}
            //         />
            //       </div>
            //     ) : (
            //       <Delete color={"disabled"} />
            //       // "Mohan Gupta"
            //     );
            //   },
            // },
            {
              field: "shareLink",
              headerName: "Share To User",
              width: 70,
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={""}
                    background={"#ff9a62"}
                    onClick={() => {
                      controller.onShareButtonPressed(
                        index.api.getRow(index.row.id)?.apartment?.name,
                        index.api.getRow(index.row.id)?.apartment?.apartmentType
                          ? index.api.getRow(index.row.id)?.apartment
                              ?.apartmentType
                          : "Apartment",
                        index.api.getRow(index.row.id).aparmentId
                      );
                    }}
                    iconName={
                      <ShareIcon sx={{ height: "25px", color: "#fff" }} />
                    }
                  />
                );
              },
            },
            {
              field: "Qr Code",
              headerName: "QR Code",
              width: 70,
              headerAlign: "center",
              align: "center",
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    buttonLoading={
                      this.props.controller.state.genrateQrLoading &&
                      this.props.controller.state.apartmentToGenrateQrCode ==
                        index.api.getRow(index.row.id).apartment._id
                        ? true
                        : false
                    }
                    width={"60px"}
                    background={currentColor}
                    onClick={() => {
                      this.props.controller.onQrButtonPressed(
                        index.api.getRow(index.row.id)
                      );
                    }}
                    iconName={
                      this.props.controller.state.genrateQrLoading &&
                      this.props.controller.state.apartmentToGenrateQrCode ==
                        index.api.getRow(index.row.id).apartment._id ? (
                        ""
                      ) : (
                        <QrCode />
                      )
                    }
                  />
                );
              },
            },
            {
              field: "manage",
              headerName: "Roles",
              headerAlign: "center",
              width: 70,
              renderCell: (index) => {
                return index.api.getRow(index.row.id).role !=
                  AdminRoles.Viewer &&
                  index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={""}
                    background={"#fede68"}
                    onClick={() => {
                      controller.props.navigate(
                        `/dashboard/assign-admin/${
                          index.api.getRow(index.row.id).aparmentId
                        }`
                      );
                    }}
                    iconName={<GrUserAdmin size={24} />}
                  />
                ) : (
                  <ButtonWithIcon
                    buttonName={""}
                    background={"lightgray"}
                    width={"60px"}
                    iconName={<GrUserAdmin size={24} />}
                  />
                );
              },
            },
            {
              field: "setting",
              headerName: "Settings",
              width: 80,
              renderCell: (index) => {
                return index.api.getRow(index.row.id).role !=
                  AdminRoles.Viewer &&
                  index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={""}
                    background={"#fa454"}
                    onClick={() => {
                      controller.props.navigate(
                        `/dashboard/apartment/settings/${
                          index.api.getRow(index.row.id).aparmentId
                        }`
                      );
                    }}
                    iconName={<IoSettingsSharp size={25} />}
                  />
                ) : (
                  <ButtonWithIcon
                    buttonName={""}
                    background={"lightgray"}
                    width={"60px"}
                    iconName={<IoMdLock size={25} />}
                  />
                );
              },
            },
            {
              field: "subdivision",
              headerName: currentBuildingType?.buildingName
                ? currentBuildingType?.buildingName
                : "Buildings",
              width: 100,
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={" "}
                    background={"#b6f36a"}
                    iconName={<BsBuildingGear size={24} />}
                    onClick={() => {
                      console.log(index.api.getRow(index.row.id));
                      // return;
                      const {
                        setSuperParent,
                        subdivisionTreeNavigaitonStack,
                        setCurrentBuildingType,
                        setAPartmentOfSubdivision,
                      }: any = this.props.controller.context;
                      setAPartmentOfSubdivision(index.api.getRow(index.row.id));
                      subdivisionTreeNavigaitonStack.push({
                        name: index.api.getRow(index.row.id).name,
                        id: index.api.getRow(index.row.id).aparmentId,
                      });
                      controller.props.navigate(
                        `/dashboard/subdivision/${
                          index.api.getRow(index.row.id).aparmentId
                        }`
                      );
                      setSuperParent(index.api.getRow(index.row.id).aparmentId);
                      setCurrentBuildingType(
                        getBuildingDetailsWithTypeName(
                          index.api.getRow(index.row.id)?.apartment
                            ?.apartmentType
                            ? index.api.getRow(index.row.id)?.apartment
                                ?.apartmentType
                            : "Apartment"
                        )
                      );
                    }}
                  />
                );
              },
            },
            {
              field: "manageLocks",
              headerName: "Locks",
              width: 70,
              renderCell: (index) => {
                return index.api.getRow(index.row.id).role !=
                  AdminRoles.Viewer &&
                  index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={""}
                    background={"#c9a0ff"}
                    onClick={() => {
                      controller.props.navigate(
                        `/dashboard/managelocks/${
                          index.api.getRow(index.row.id).aparmentId
                        }/${LevelsForLock.APARTMENT}`
                      );
                    }}
                    iconName={<IoMdLock size={25} />}
                  />
                ) : (
                  <ButtonWithIcon
                    buttonName={""}
                    background={"lightgray"}
                    width={"60px"}
                    iconName={<IoMdLock size={25} />}
                  />
                );
              },
            },
          ]
        : [
            {
              field: "logo",
              headerName: "Logo",
              width: 120,
              headerAlign: "center",
              renderCell: (index) => {
                return index.api.getRow(index.row.id)?.apartment?.thumbLogo ? (
                  <div className={style.logo_container}>
                    <img
                      src={
                        imageBaseUrl +
                        index.api.getRow(index.row.id).apartment.thumbLogo
                      }
                    />
                  </div>
                ) : (
                  <div className={style.logo_container}>
                    <p>--</p>
                  </div>
                );
              },
            },
            { field: "name", headerName: "Name", width: 180 },
            { field: "address", headerName: "Address", width: 200 },
            // { field: "phoneNumber", headerName: "Phone Number", width: 150 },
            {
              field: "edit",
              headerName: "Edit",
              width: 50,
              renderCell: (index) => {
                return index.api.getRow(index.row.id).role ==
                  AdminRoles.Owner ||
                  index.api.getRow(index.row.id).role ==
                    AdminRoles.SuperAdmin ||
                  admin.role == AdminRoles.SuperAdmin ? (
                  <div
                    className="flex-row justify-center gap-5 cursor-pointer"
                    onClick={() => {
                      this.props.controller.onShowEditApartmentWithValue(
                        index.api.getRow(index.row.id)
                      );
                    }}
                  >
                    <Edit />
                  </div>
                ) : (
                  <Edit color={"disabled"} />
                );
              },
            },
            // {
            //   field: "Remove Logo",
            //   headerName: "Remove Logo",
            //   width: 100,
            //   renderCell: (index) => {
            //     return index.api.getRow(index.row.id).role != AdminRoles.Viewer &&
            //       index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
            //       <div className="flex-row justify-center gap-5 cursor-pointer">
            //         <CustomAlert
            //           loading={controller.state.loading == loadingEnu.REMOVELOGO}
            //           msg={"Do you want to remove logo?"}
            //           onPressDelete={() => {
            //             controller.removeLogo(
            //               index.api.getRow(index.row.id).apartment._id
            //             );
            //           }}
            //         />
            //       </div>
            //     ) : (
            //       <Delete color={"disabled"} />
            //     );
            //   },
            // },
            // {
            //   field: "delete",
            //   headerName: "Delete",
            //   width: 100,
            //   renderCell: (index) => {
            //     return index.api.getRow(index.row.id).role ==
            //       AdminRoles.Owner ||
            //       index.api.getRow(index.row.id).role ==
            //         AdminRoles.SuperAdmin ||
            //       admin.role == AdminRoles.SuperAdmin ? (
            //       <div className="flex-row justify-center gap-5 cursor-pointer">
            //         <CustomAlert
            //           onPressDelete={() => {
            //             console.log(index.api.getRow(index.row.id));
            //             controller.deleteApartment(
            //               index.api.getRow(index.row.id).apartment._id
            //             );
            //           }}
            //         />
            //       </div>
            //     ) : (
            //       <Delete color={"disabled"} />
            //     );
            //   },
            // },
            {
              field: "shareLink",
              headerName: "Share To User",
              width: 70,
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={""}
                    background={"#ff9a62"}
                    onClick={() => {
                      controller.onShareButtonPressed(
                        index.api.getRow(index.row.id)?.apartment?.name,
                        index.api.getRow(index.row.id)?.apartment?.apartmentType
                          ? index.api.getRow(index.row.id)?.apartment
                              ?.apartmentType
                          : "Apartment",
                        index.api.getRow(index.row.id).aparmentId
                      );
                    }}
                    iconName={
                      <ShareIcon sx={{ height: "25px", color: "#fff" }} />
                    }
                  />
                );
              },
            },
            {
              field: "Qr Code",
              headerName: "QR Code",
              width: 70,
              headerAlign: "center",
              align: "center",
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    buttonLoading={
                      this.props.controller.state.genrateQrLoading &&
                      this.props.controller.state.apartmentToGenrateQrCode ==
                        index.api.getRow(index.row.id).apartment._id
                        ? true
                        : false
                    }
                    width={"60px"}
                    background={currentColor}
                    onClick={() => {
                      this.props.controller.onQrButtonPressed(
                        index.api.getRow(index.row.id)
                      );
                    }}
                    iconName={
                      this.props.controller.state.genrateQrLoading &&
                      this.props.controller.state.apartmentToGenrateQrCode ==
                        index.api.getRow(index.row.id).apartment._id ? (
                        ""
                      ) : (
                        <QrCode />
                      )
                    }
                  />
                );
              },
            },

            {
              field: "manage",
              headerName: "Roles",
              headerAlign: "center",
              width: 70,
              renderCell: (index) => {
                return index.api.getRow(index.row.id).role !=
                  AdminRoles.Viewer &&
                  index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={""}
                    background={"#fede68#fede68#fede68"}
                    onClick={() => {
                      controller.props.navigate(
                        `/dashboard/assign-admin/${
                          index.api.getRow(index.row.id).aparmentId
                        }`
                      );
                    }}
                    iconName={<GrUserAdmin size={24} />}
                  />
                ) : (
                  <ButtonWithIcon
                    buttonName={""}
                    background={"lightgray"}
                    width={"60px"}
                    iconName={<GrUserAdmin size={24} />}
                  />
                );
              },
            },
            {
              field: "setting",
              headerName: "Settings",
              width: 80,
              renderCell: (index) => {
                return index.api.getRow(index.row.id).role !=
                  AdminRoles.Viewer &&
                  index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={""}
                    background={"#fa454"}
                    onClick={() => {
                      controller.props.navigate(
                        `/dashboard/apartment/settings/${
                          index.api.getRow(index.row.id).aparmentId
                        }`
                      );
                    }}
                    iconName={<IoSettingsSharp size={25} />}
                  />
                ) : (
                  <ButtonWithIcon
                    buttonName={""}
                    background={"lightgray"}
                    width={"60px"}
                    iconName={<IoMdLock size={25} />}
                  />
                );
              },
            },
            {
              field: "subdivision",
              headerName: currentBuildingType?.buildingName
                ? currentBuildingType?.buildingName
                : "Buildings",
              width: 100,
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    width={"60px"}
                    buttonName={" "}
                    background={"#b6f36a"}
                    iconName={<BsBuildingGear size={24} />}
                    onClick={() => {
                      console.log(index.api.getRow(index.row.id));
                      // return;
                      const {
                        setSuperParent,
                        subdivisionTreeNavigaitonStack,
                        setCurrentBuildingType,
                        setAPartmentOfSubdivision,
                      }: any = this.props.controller.context;
                      setAPartmentOfSubdivision(index.api.getRow(index.row.id));
                      subdivisionTreeNavigaitonStack.push({
                        name: index.api.getRow(index.row.id).name,
                        id: index.api.getRow(index.row.id).aparmentId,
                      });
                      controller.props.navigate(
                        `/dashboard/subdivision/${
                          index.api.getRow(index.row.id).aparmentId
                        }`
                      );
                      setSuperParent(index.api.getRow(index.row.id).aparmentId);
                      setCurrentBuildingType(
                        getBuildingDetailsWithTypeName(
                          index.api.getRow(index.row.id)?.apartment
                            ?.apartmentType
                            ? index.api.getRow(index.row.id)?.apartment
                                ?.apartmentType
                            : "Apartment"
                        )
                      );
                    }}
                  />
                );
              },
            },
          ];

    return (
      <div className="p-3 lg:px-10 lg:py-5 flex-col items-center justify-center  lg:max-w-[84vw] w-[100vw]">      
        {this.props.controller.state.showLinkCopyPopup && (
          <GenerateUserLink
            onCopyButtonPressed={this.props.controller.onCopyButtonPressed}
            onClose={() => {
              this.props.controller.setState({ showLinkCopyPopup: false });
            }}
            loading={this.props.controller.state.linkGenrateLoading}
          />
        )}
        {this.props.controller.state.isShowMap ? (
          <SimpleMap
            onCancel={() => this.props.controller.mapCancelButtonPressed()}
            onSubmit={
              this.props.controller.state.isAddApartment
                ? this.props.controller.mapSubmitButtonPressed
                : this.props.controller.mapSubmitButtonPressedForEdit
            }
          />
        ) : (
          <>
            {controller.state.isShowAddApartment && (
              <AddApartments
                controller={controller}
                onClose={controller.onShowAddApartment}
                onSubmit={controller.addAparment}
                buttonLoading={
                  controller.state.loading == loadingEnu.ADDBUTTONLOADING
                    ? true
                    : false
                }
                textFields={[]}
                isAddApartment={true}
              />
            )}
            {controller.state.isShowEditApartment && (
              <AddApartments
                controller={controller}
                onClose={controller.onShowEditApartment}
                onSubmit={controller.editAparment}
                isAddApartment={false}
                buttonLoading={
                  controller.state.loading == loadingEnu.EDITLOADING
                    ? true
                    : false
                }
                textFields={[]}
              />
            )}
            <div className="flex gap-10 w-60">
              {(admin.role == AdminRoles.SuperAdmin ||
                admin.role == AdminRoles.Owner) && (
                <ButtonWithIcon
                  buttonName={"Add Building"}
                  iconName={<Add />}
                  onClick={controller.onShowAddApartment}
                />
              )}
            </div>
            {this.props.controller.state.loading == loadingEnu.PAGELOADING ? (
              <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll">
                <LinearIndeterminate />
                <StyledDataGrid
                  columns={columns}
                  hideFooterPagination={true}
                  style={{ background: "#fff", height: "90%" }}
                />
              </div>
            ) : (
              <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll">
                <StyledDataGrid
                  rows={controller.state.apartments.map((row: any, index) => ({
                    ...row,
                    id: index + 1,
                    qrCode: row.apartment.qrCode,
                    website: row.apartment.website,
                    address: row.apartment.address,
                    location: row.apartment.location,
                    aparmentId: row.apartment._id,
                    name: row.apartment.name,
                    phoneNumber: row.apartment.phoneNumber,
                  }))}
                  columns={columns}
                  style={{ background: "#fff", height: "100%" }}
                  // hideFooterPagination={true}
                  disableColumnSelector={true}
                  disableRowSelectionOnClick={true}
                  autoPageSize={true}
                  pagination={true}
                  // pageSizeOptions={[10,20,30]}
                  onRowDoubleClick={(row: any) => {
                    console.log(row.row.name);
                    const {
                      setSuperParent,
                      subdivisionTreeNavigaitonStack,
                    }: any = this.props.controller.context;
                    subdivisionTreeNavigaitonStack.push({
                      name: row.row.name,
                      id: row.row.aparmentId,
                    });
                    controller.props.navigate(
                      `/dashboard/subdivision/${row.row.aparmentId}`
                    );
                    setSuperParent(row.row.aparmentId);
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default AparmetsView;
